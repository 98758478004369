<template>
    <div class="w-full">
		<SiteHeader />
		<section id="benefits" class="w-full py-16 bg-siteRed-100">
			<div class="container mx-auto">
				<h2 class="mb-4 text-6xl leading-10 text-center text-white lg:text-7xl font-cormorant">Benefits</h2>
				<h3 class="text-2xl font-thin text-center text-white font-roboto">of Body Sculpting Treatments</h3>
			</div>
			<div class="container pt-8 mx-auto">
				<div class="grid items-start grid-cols-2 gap-10 md:grid-cols-2 lg:grid-cols-4">
					<div class="flex flex-wrap">
						<div class="w-full"><img src="/images/icon-01.png" class="w-1/2 mx-auto mb-6 lg:w-1/2" /></div>
						<div class="items-start w-full mx-auto lg:w-10/12">
							<h2 class="text-2xl font-light leading-tight text-center text-white lg:text-3xl font-roboto">Non-invasive</h2>
							<h3 class="mt-2 text-lg font-thin leading-tight text-center text-white lg:text-xl lg:mt-4">No needles, no downtime</h3>
						</div>
					</div>
					<div class="flex flex-wrap">
						<div class="w-full"><img src="/images/icon-02.png" class="w-1/2 mx-auto mb-6 lg:w-1/2" /></div>
						<div class="items-start w-full mx-auto lg:w-3/4">
							<h2 class="text-2xl font-light leading-tight text-center text-white lg:text-3xl font-roboto">U.S FDA-Cleared</h2>
							<h3 class="mt-2 text-lg font-thin leading-tight text-center text-white lg:text-xl lg:mt-4">Proven safe & effective</h3>
						</div>
					</div>
					<div class="flex flex-wrap">
						<div class="w-full"><img src="/images/icon-03.png" class="w-1/2 mx-auto mb-6 lg:w-1/2" /></div>
						<div class="items-start w-full mx-auto lg:w-3/4">
							<h2 class="text-2xl font-light leading-tight text-center text-white lg:text-3xl font-roboto">Dual Function</h2>
							<h3 class="mt-2 text-lg font-thin leading-tight text-center text-white lg:text-xl lg:mt-4">Reduce fat & build muscle</h3>
						</div>
					</div>
					<div class="flex flex-wrap">
						<div class="w-full"><img src="/images/icon-04.png" class="w-1/2 mx-auto mb-6 lg:w-1/2" /></div>
						<div class="items-start w-full mx-auto lg:w-3/4">
							<h2 class="text-2xl font-light leading-tight text-center text-white lg:text-3xl font-roboto">High Efficiency</h2>
							<h3 class="mt-2 text-lg font-thin leading-tight text-center text-white lg:text-xl lg:mt-4">Multiple treatment areas</h3>
						</div>
					</div>
					
				</div>
			</div>
		</section>
		<section class="w-full py-16">
			<div class="container px-0 mx-auto">
				<div class="w-full mx-auto mb-16 text-center px-5">
					<h2 class="mb-2 text-5xl leading-none text-center text-siteRed-100 lg:text-7xl font-cormorant">Target Troubled Areas</h2>
					<!-- <span class="text-2xl text-siteRed-100">With Effective Anti-Aging & Natural Healing</span> -->
				</div>
				<div class="flex flex-wrap items-start gap-y-10 px-2 justify-center">
					<!-- grid-cols-1 gap-x-4 md:grid-cols-2 lg:grid-cols-5 -->
					<div class="flex flex-wrap px-2 w-full md:w-1/2 lg:w-1/5">
						<div class="w-full text-center">
							<picture>
								<source media="(max-width: 1023px)" srcset="/images/vavavoom-img-01m.jpg">
								<source media="(min-width: 1024px)" srcset="/images/vavavoom-img-01.jpg">
								<img src="/images/vavavoom-img-01m.jpg" class="mb-6 rounded-xl mx-auto" />
							</picture>
							
							<h2 class="text-2xl font-light leading-tight text-center text-black lg:text-3xl font-roboto">Abdomen</h2>
						</div>
					</div>
					<div class="flex flex-wrap px-2 w-full md:w-1/2 lg:w-1/5">
						<div class="w-full text-center">
							<picture>
								<source media="(max-width: 1023px)" srcset="/images/vavavoom-img-02m.jpg">
								<source media="(min-width: 1024px)" srcset="/images/vavavoom-img-02.jpg">
								<img src="/images/vavavoom-img-02m.jpg" class="mb-6 rounded-xl mx-auto" />
							</picture>
							
							<h2 class="text-2xl font-light leading-tight text-center text-black lg:text-3xl font-roboto">Buttocks</h2>
						</div>
					</div>
					<div class="flex flex-wrap px-2 w-full md:w-1/2 lg:w-1/5">
						<div class="w-full text-center">
							<picture>
								<source media="(max-width: 1023px)" srcset="/images/vavavoom-img-03m.jpg">
								<source media="(min-width: 1024px)" srcset="/images/vavavoom-img-03.jpg">
								<img src="/images/vavavoom-img-03m.jpg" class="mb-6 rounded-xl mx-auto" />
							</picture>
							
							<h2 class="text-2xl font-light leading-tight text-center text-black lg:text-3xl font-roboto">Arms</h2>
						</div>
					</div>
					<div class="flex flex-wrap px-2 w-full md:w-1/2 lg:w-1/5">
						<div class="w-full text-center">
							<picture>
								<source media="(max-width: 1023px)" srcset="/images/vavavoom-img-04m.jpg">
								<source media="(min-width: 1024px)" srcset="/images/vavavoom-img-04.jpg">
								<img src="/images/vavavoom-img-04m.jpg" class="mb-6 rounded-xl mx-auto" />
							</picture>
							
							<h2 class="text-2xl font-light leading-tight text-center text-black lg:text-3xl font-roboto">Thighs</h2>
						</div>
					</div>
					<div class="flex flex-wrap px-2 w-full md:w-1/2 lg:w-1/5">
						<div class="w-full text-center">
							<picture>
								<source media="(max-width: 1023px)" srcset="/images/vavavoom-img-05m.jpg">
								<source media="(min-width: 1024px)" srcset="/images/vavavoom-img-05.jpg">
								<img src="/images/vavavoom-img-05m.jpg" class="mb-6 rounded-xl mx-auto" />
							</picture>
							
							<h2 class="text-2xl font-light leading-tight text-center text-black lg:text-3xl font-roboto">Calves</h2>
						</div>
					</div>
				</div>
				<div class="my-12 border-b border-siteRed-200 mx-5"></div>
				<div class="flex flex-wrap items-center justify-between px-5">
					<div class="w-full mb-10 lg:w-3/5 lg:mb-0">
						<h3 class="text-2xl font-roboto mb-6">With a keen eye for aesthetics, Dr Vanessa Phua can help you achieve a natural and contoured body shape.</h3>
						<h3 class="font-roboto text-base font-medium">(Zeltiq CoolSculpting and EMSculpt Neo are medical-use only devices that should only be performed by an MOH-accredited medical professional like Dr Vanessa Phua.)</h3>
					</div>
					<div class="items-end justify-end w-full lg:w-2/5">
						<div class="items-end float-left lg:float-right"><a href="#enquire" class="flex flex-wrap items-center px-8 py-4 text-xl text-white rounded-full lg:text-2xl lg:px-20 lg:float-left bg-siteRed hover:underline">Enquire Now <img src="/images/arrow-icon.png" class="h-6 ml-4" /></a></div>
					</div>
				</div>
			</div>
		</section>
		<section class="relative flex flex-wrap lg:pb-0 bg-siteRed-100">
			<div class="container flex flex-wrap mx-auto">
				<div class="w-full py-12 lg:w-3/5 lg:py-24 lg:pr-8 xl:py-32">
					<div class="mb-10">
						<h2 class="mb-2 text-5xl leading-none text-white lg:text-7xl font-cormorant">Effective Body Sculpting</h2>
						<span class="text-2xl text-white">With Dr Vanessa Phua</span>
					</div>
					<p class="text-white">Dr Vanessa Phua is an experienced practitioner of non-invasive body sculpting and contouring treatments.</p>
					<p class="text-white">She dedicates one-on-one consultations with every patient to tailor personalized treatments for their unique body type. Dr Phua is well-trained and experienced in both Zeltiq Coolsculpting and Emsculpt Neo treatments.</p>
					<p class="text-white">With Dr Phua’s experience and attention to detail, patients can rest assured that all body contouring treatments performed at the clinic are done with their safety and comfort in mind.</p>
				</div>
				<div id="drvanessaphua" class="w-0 h-0"></div>
				<div class="right-0 z-10 hidden w-full h-full bg-center bg-no-repeat bg-cover lg:block lg:absolute lg:w-2/5 xl:bg-top" style="background-image: url('/images/dr-vanessa-phua.jpg')"></div>
			</div>
			<img src="/images/dr-vanessa-phua.jpg" class="w-full h-full lg:hidden" />
		</section>
		<section class="relative flex flex-wrap w-full pb-12 lg:pb-0">
			<div class="absolute left-0 z-10 hidden w-full h-full bg-center bg-no-repeat bg-cover lg:block lg:w-2/5 xl:bg-top" style="background-image: url('/images/dr-vanessa-phua-photo.jpg')"></div>
			<div class="container flex flex-wrap mx-auto">
				<div class="w-full py-12 ml-auto lg:w-3/5 lg:py-24 lg:pl-16 xl:py-32">
					<div class="mb-10">
						<span class="mb-4 text-2xl text-siteRed-100">Meet Our Doctor</span>
						<h2 class="text-5xl leading-none text-siteRed-100 lg:text-7xl font-cormorant">Dr Vanessa Phua</h2>
					</div>
					<p>Dr Vanessa Phua has a history of training in Ophthalmology and Aesthetic Medicine internationally. Educated in the United Kingdom with an Ophthalmology surgical background, she has also built a keen interest in aesthetic rejuvenation and non-invasive body contouring for individuals. Her signature body contouring treatments are Zeltiq Coolsculpting and Emsculpt Neo.</p>
					<p>Dr Phua believes the pursuit of beauty is not just skin deep—it is both an art and a science performing the most appropriate and beneficial treatment for the patient. With Dr Phua's background training, artistic flair and passion, she always sees good results.</p>
					<p class="font-medium">Qualifications</p>
					<ul class="pl-4 mb-6 leading-relaxed list-disc">
						<li class="pb-2 pl-2">BMed Science, BMBS (Nottingham, UK)</li>
						<li class="pb-2 pl-2">MRCS (Edinburgh), MMED (S'pore) Ophthalmology</li>
						<li class="pb-2 pl-2">Dip Practical Dermatology (Cardiff, Wales, UK)</li>
						<li class="pb-2 pl-2">Filler Rejuvenation For Body & Face Contouring Certified</li>
						<li class="pb-2 pl-2">Thermage certified Physician</li>
						<li class="pb-2 pl-2">Ulthera (Focused Ultrasound Skin Lifting) Certified Physician & Trainer</li>
					</ul>
				</div>
			</div>
			<img src="/images/dr-vanessa-phua-photo.jpg" class="w-full h-full lg:hidden" />
		</section>
		<section class="w-full bg-[#E8EFF0]">
			<div class="container py-12 lg:py-16">
				<div class="grid grid-cols-1 gap-4 md:grid-cols-2 lg:gap-8">
					<div class="overflow-hidden bg-white rounded-lg">
						<img id="coolsculpting" src="/images/coolsculpting.jpg" class="w-full h-auto" width="732" height="462" loading="lazy" />
						<div class="p-4 lg:p-8">
							<h3 class="text-4xl leading-none text-siteRed-100 lg:text-5xl font-cormorant mb-8 lg:mb-10">CoolSculpting® by ZELTIQ</h3>
							<table class="table-auto">
								<tr>
									<td valign="top" class="pb-4 md:pb-[68px] lg:pb-[82px] xl:pb-[53px] pr-4 font-medium lg:pr-8">Technology</td>
									<td valign="top" class="pb-4 lg:pb-6">Cryolipolysis® (Fat Freezing)</td>
								</tr>
								<tr>
									<td valign="top" class="pb-4 lg:pb-[53px] xl:pb-4 pr-4 font-medium lg:pr-8">Benefits</td>
									<td valign="top" class="pb-4 lg:pb-6">Freeze away stubborn fats naturally</td>
								</tr>
								<tr>
									<td valign="top" class="pb-4 pr-4 font-medium lg:pb-6 lg:pr-8">Treatment&nbsp;Time</td>
									<td valign="top" class="pb-4 lg:pb-6">30-60 minutes per session</td>
								</tr>
								<tr>
									<td valign="top" class="pb-4 md:pb-[42px] pr-4 font-medium lg:pb-6 lg:pr-8">Results</td>
									<td valign="top" class="pb-4 lg:pb-6">Between 2 – 6 months</td>
								</tr>
								<tr>
									<td valign="top" class="pb-4 md:pb-[146px] lg:pb-[169px] pr-4 font-medium lg:pr-8">Treatment&nbsp;Areas</td>
									<td valign="top" class="pb-4 lg:pb-6">
										<ul>
											<li>Abdomen</li>
											<li>Thigh</li>
											<li>Bra fat</li>
											<li>Double chin</li>
											<li>Flanks/Sides</li>
											<li>Upper arms</li>
										</ul>
									</td>
								</tr>
								<tr>
									<td valign="top" class="pb-4 pr-4 font-medium lg:pb-6 lg:pr-8">Clinical&nbsp;Studies</td>
									<td valign="top" class="pb-4 lg:pb-6">Up to 27% fat reduction</td>
								</tr>
							</table>
						</div>
					</div>
					<div class="overflow-hidden bg-white rounded-lg">
						<img id="emsculpt" src="/images/emsculpt.jpg" class="w-full h-auto" width="732" height="462" loading="lazy" />
						<div class="p-4 lg:p-8">
							<h3 class="text-4xl leading-none text-siteRed-100 lg:text-5xl font-cormorant mb-8 lg:mb-10">EMSculpt NEO<br class="hidden md:inline xl:hidden">&nbsp;</h3>
							<table class="table-auto">
								<tr>
									<td valign="top" class="pb-4 md:pb-[68px] lg:pb-[82px] xl:pb-[53px] pr-4 font-medium lg:pr-8">Technology</td>
									<td valign="top" class="pb-4 lg:pb-6">High Intensity Focused ElectroMagnetic (HIFEM) & Radio-Frequency (RF)</td>
								</tr>
								<tr>
									<td valign="top" class="pb-4 lg:pb-[53px] xl:pb-4 pr-4 font-medium lg:pr-8">Benefits</td>
									<td valign="top" class="pb-4 lg:pb-6">Build Muscles and Burn Fat</td>
								</tr>
								<tr>
									<td valign="top" class="pb-4 pr-4 font-medium lg:pb-6 lg:pr-8">Treatment&nbsp;Time</td>
									<td valign="top" class="pb-4 lg:pb-6">30 minutes per session</td>
								</tr>
								<tr>
									<td valign="top" class="pb-4 md:pb-[42px] pr-4 font-medium lg:pb-6 lg:pr-8">Results</td>
									<td valign="top" class="pb-4 lg:pb-6">6-8 weeks after last treatment</td>
								</tr>
								<tr>
									<td valign="top" class="pb-4 md:pb-[146px] lg:pb-[169px] pr-4 font-medium lg:pr-8">Treatment&nbsp;Areas</td>
									<td valign="top" class="pb-4 lg:pb-6">
										<ul>
											<li>Abdomen</li>
											<li>Buttocks</li>
											<li>Arms</li>
											<li>Calves</li>
											<li>Thighs</li>
										</ul>
									</td>
								</tr>
								<tr>
									<td valign="top" class="pb-4 pr-4 font-medium lg:pb-6 lg:pr-8">Clinical&nbsp;Studies</td>
									<td valign="top" class="pb-4 lg:pb-6">An average of 25% increase in muscle mass and 30% less fat in the treatment area</td>
								</tr>
							</table>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section class="w-full py-16">
			<div class="container mx-auto">
				<h2 class="mb-10 text-6xl text-center text-black lg:text-7xl font-cormorant">FAQ</h2>
				<div>
					<div class="mb-2">
						<div @click="index = 0" class="flex items-center justify-between px-8 py-4 rounded-lg cursor-pointer bg-siteRed-100">
							<h6 class="text-lg text-white lg:text-2xl">Am I suitable for body contouring and sculpting treatment?</h6>
							<div class="text-2xl transition duration-500 delay-200 transform " :class="index === 0 ? 'rotate-45' : ''">
								<svg viewBox="0 0 20 20" fill="currentColor" class="w-6 h-6 text-white plus"><path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd"></path></svg>
							</div>
						</div>
						<collapse-transition>
							<div v-show="index === 0" class="p-8">
								<p class="mb-4 text-black">Coolsculpting and Emsculpt Neo are both not weight loss procedures; so the ideal candidate is someone who is not severely overweight and are generally healthy (with no contraindications to the procedure), who wishes to eliminate stubborn fat (and build muscle – for Emsculpt Neo) non-invasively.</p>
							</div>
						</collapse-transition>
					</div>
					<div class="mb-2">
						<div @click="index = 1" class="flex items-center justify-between px-8 py-4 rounded-lg cursor-pointer bg-siteRed-100">
							<h6 class="text-lg text-white lg:text-2xl">Are the treatments painful?</h6>
							<div class="text-2xl transition duration-500 delay-200 transform " :class="index === 1 ? 'rotate-45' : ''">
								<svg viewBox="0 0 20 20" fill="currentColor" class="w-6 h-6 text-white plus"><path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd"></path></svg>
							</div>
						</div>
						<collapse-transition>
							<div v-show="index === 1" class="p-8">
								<p class="mb-4 text-black">These two non-invasive treatments yield only mild discomfort that is manageable.</p>
								<p class="mb-4 text-black"><strong>Zeltiq Coolsculpting</strong> – You may experience sensations of pulling, tugging, aching and coldness at the treatment site, which subside as the area gradually becomes numb.</p>
								<p class="mb-4 text-black"><strong>Emsculpt Neo</strong> – You will feel a series of painless but strong muscle contractions along with warmth at the treatment site.</p>
								<p class="mb-4 text-black">In both treatments, patients have been found to multitask during their sessions; so read a book, watch TV, relax, or whatever feels comfortable for you!</p>
							</div>
						</collapse-transition>
					</div>
					<div class="mb-2">
						<div @click="index = 2" class="flex items-center justify-between px-8 py-4 rounded-lg cursor-pointer bg-siteRed-100">
							<h6 class="text-lg text-white lg:text-2xl">Are there side effects?</h6>
							<div class="text-2xl transition duration-500 delay-200 transform " :class="index === 2 ? 'rotate-45' : ''">
								<svg viewBox="0 0 20 20" fill="currentColor" class="w-6 h-6 text-white plus"><path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd"></path></svg>
							</div>
						</div>
						<collapse-transition>
							<div v-show="index === 2" class="p-8">
								<p class="mb-4 text-black">Post procedure, common side effects include bruising, swelling, redness, skin sensitivity, numbness and firmness. These side effects are temporary and disappear within days or weeks.</p>
							</div>
						</collapse-transition>
					</div>
				</div>
			</div>
		</section>
		<section class="w-full py-16 bg-siteRed-200">
			<div class="container mx-auto text-center"><a name="enquire"></a>
				<div class="w-full mx-auto lg:w-3/4">
					<h2 class="mb-10 text-5xl leading-none text-center text-siteRed-100 lg:text-7xl font-cormorant">Achieve a naturally contoured body with ease today.</h2>
					<span class="text-2xl text-siteRed-100">Find out more about our non-invasive body contouring treatments.<br>Send in your enquiry below!</span>
				</div>
				<div class="w-full mx-auto mt-10 lg:w-3/4">
					<ContactForm />
					<!-- <iframe allowTransparency="true" style="min-height:450px; height:inherit; overflow:auto;" width="100%" id="contactform123" name="contactform123" marginwidth="0" marginheight="0" frameborder="0" src="//feedback.activamedia.com.sg/my-contact-form-5756594.html"></iframe> -->
				</div>
			</div>
		</section>
		<section class="flex flex-wrap">
			<div class="w-full bg-center bg-no-repeat bg-cover lg:w-1/2" style="background-image: url('/images/clinic-photo.jpg')">
				<img src="/images/clinic-photo.jpg" class="block lg:hidden" />
			</div>
			<div class="flex justify-start w-full lg:w-1/2 bg-siteRed-100">
				<div class="py-12 container-half lg:pl-16 lg:py-24">
					<h2 class="mb-10 text-6xl leading-none text-white lg:text-8xl font-cormorant">Our Clinic</h2>
					<p class="text-white">Established in 1999, The Chelsea Clinic is dedicated to the pursuit of aesthetic excellence and places a strong emphasis on the holistic approach to a patient’s well-being focuses through personalized health care.</p>
					<p class="text-white">Our mission is to provide safe, effective and affordable treatments to our clients without compromising on the quality of our work.</p>
				</div>
			</div>
    	</section>
        <SiteFooter />
    </div>
</template>

<script>
import {CollapseTransition} from "vue2-transitions";
import SiteHeader from '@/components/SiteHeader.vue'
import ContactForm from '@/components/ContactForm.vue'
import SiteFooter from '@/components/SiteFooter.vue'

export default {
	name: 'Home',
	components: {
		SiteHeader,
		ContactForm,
		SiteFooter,
		CollapseTransition
	},
	data(){
		return {
			index: 0,
		}
	},
}
</script>
<style>

@screen lg{
	.bg-grad{
		background: rgb(247,247,247);
		background: -moz-linear-gradient(90deg, rgba(247,247,247,1) 0%, rgba(247,247,247,1) 60%, rgba(138,34,36,1) 60%, rgba(138,34,36,1) 100%);
		background: -webkit-linear-gradient(90deg, rgba(247,247,247,1) 0%, rgba(247,247,247,1) 60%, rgba(138,34,36,1) 60%, rgba(138,34,36,1) 100%);
		background: linear-gradient(90deg, rgba(247,247,247,1) 0%, rgba(247,247,247,1) 60%, rgba(138,34,36,1) 60%, rgba(138,34,36,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f7f7f7",endColorstr="#8a2224",GradientType=1); 
	}
}
</style>